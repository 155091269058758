<template>
	<div>
		<p class="text-body-1 text-justify" style="max-width: 95%">
			{{ setupInfo.accountType.alumni.value ? $t('studies.setupAlumniHelp1') : $t('studies.setupStudentHelp1') }}
		</p>
		<p class="text-body-1 text-justify" style="max-width: 95%">
			{{ setupInfo.accountType.alumni.value ? $t('studies.setupAlumniHelp2') : $t('studies.setupStudentHelp2') }}
		</p>

		<template v-if="setupInfo.accountType.student.value">
			<v-alert
				v-for="student in unverifiedStudents"
				:key="`unverifiedStudent${student.id}`"
				border="top"
				elevation="8"
				type="warning"
				color="orange darken-3"
				max-width="500"
				dark
				class="mx-auto text-center"
			>
				<i18n path="studies.askNotVerified" tag="p">
					<template #email>
						{{ student.email }}
					</template>
					<template #here>
						<a class="text-decoration-none" @click="callSendVerifyStudentEmail(student)">
							{{ $t('settings.here') }}
						</a>
					</template>
				</i18n>
			</v-alert>
		</template>

		<v-row>
			<v-col cols="12">
				<DegreeAdd class="float-right" />
			</v-col>
		</v-row>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="studies.length">
				<v-row key="filteresResults" no-gutters>
					<v-col cols="12" xl="4" md="6" sm="12" v-for="(study, index) in studies" :key="`study${index}`" class="px-1 d-flex flex-column">
						<DegreeCard
							:study-i-d="study.id"
							:student="study.student"
							:degree="study.degree"
							:study-ended="study.ended"
							:sent-student-email="sentStudentEmail"
						/>
					</v-col>
				</v-row>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>
		</v-fade-transition>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'Degrees',
	props: {
		studies: {
			type: Array,
			required: true,
		},
		studentEmails: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			sentStudentEmail: '',
		}
	},
	components: {
		DegreeCard: () => import('@/components/studies/DegreeCard.vue'),
		DegreeAdd: () => import('@/components/studies/DegreeAdd.vue'),
	},
	computed: {
		unverifiedStudents() {
			return this.studentEmails.filter((student) => !student.verified.value)
		},
		...mapGetters({
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		callSendVerifyStudentEmail(student) {
			this.sendVerifyStudentEmail({ student })
		},
		...mapActions('studies', ['sendVerifyStudentEmail']),
	},
}
</script>
